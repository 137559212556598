import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'

class InformativaSito extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Privacy Policy Sito Web" description={'Consulta la privacy policy del sito web di Dinamiza.'} />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Privacy policy sito web</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

                  <h3>INFORMATIVA AI SENSI AI SENSI DEGLI ARTT. 13 e 14 DEL REGOLAMENTO UE 2016/679 “REGOLAMENTO GENERALE SULLA PROTEZIONE DEI DATI”</h3>
                  <p>Starsoftware S.r.l. è impegnata nella protezione dei Dati Personali che le vengono affidati. Pertanto, la loro gestione e la loro sicurezza vengono garantite con la massima attenzione, conformemente a quanto richiesto dalla normativa sulla protezione dei dati personali Regolamento UE 679/2016 di seguito Regolamento “GDPR”.</p>
                  <p>Questa informativa, illustra chi siamo, per quali scopi potremmo utilizzare i Suoi dati, come li gestiamo, a chi potrebbero essere comunicati, dove potrebbero essere trasferiti e quali sono i diritti previsti dal Regolamento.</p>

                  <h4>TITOLARE DEL TRATTAMENTO</h4>
                  <p>I suoi dati saranno trattati da Starsoftware S.r.l. in qualità di titolare del trattamento con sede legale in Via Verga n. 3/A, 30038, Spinea (VE), Italia<br />P.Iva 03352380277
                  <ul className="lista_generica lista_competenze">
                     <li>tel. <a href="tel:00390419980624 " target="_blank"></a>041/9980624</li>
                     <li>fax. <a href="tel:00390415088091" target="_blank"></a>041/5088091</li>
                    </ul>
                  </p>

                  <h4>AMBITO DI APPLICAZIONE</h4>
                  <p>La presente informativa è rivolta a tutti i soggetti che per qualsiasi ragione vengano in contatto con il Titolare, ivi compresi i visitatori del sito internet, e a tutti gli interessati del trattamento dei dati, siano essi persone fisiche o giuridiche, enti o associazioni, quali clienti o fornitori di Starsoftware. La presente informativa integra e aggiorna le informative che di volta in volta vengono rese dal Titolare oralmente o per iscritto nell’ambito della stipulazione di singoli contratti o comunque all’atto della raccolta dei Dati.</p>

                  <h4>FINALITÀ DEL TRATTAMENTO E BASE GIURIDICA</h4>
                  <p>I dati forniti dall’interessato sono da intendersi obbligatori per le finalità descritte nel seguito ove non specificatamente indicato in fase di raccolta dati.<br />Le finalità dei trattamenti sono specificate al momento della raccolta dati e comprendono:
                    <ul className="lista_generica lista_competenze">
                      <li>Iscrizione alla newsletter</li>
                    </ul>
                  </p>
                  <p>Starsoftware S.r.l. utilizzerà il suo contatto e-mail esclusivamente al fine di inviarLe la Newsletter e gli aggiornamenti della nostra realtà relativi a partecipazione di eventi, nuove pubblicazioni, approfondimenti su prodotti ecc.</p>
                  <p>Il trattamento sarà effettuato sulla base del Suo consenso, raccolto mediante conferimento della email nella sezione dedicata. Detto che il conferimento dei dati è facoltativo l’eventuale rifiuto a fornire i dati comporta l’impossibilità di poter iscriversi al servizio newsletter.
                    <ul className="lista_generica lista_competenze">
                      <li>Contatti per richiesta informazioni</li>
                    </ul>
                  </p>
                  <p>Starsoftware S.r.l. utilizzerà i suoi contatti (azienda, nome, cognome, e-mail, nazione) esclusivamente al fine di rispondere per mezzo posta elettronica a eventuali richieste di informazioni.</p>
                  <p>Il trattamento sarà effettuato sulla base del Suo consenso raccolto mediante la compilazione del form dedicato. Detto che il conferimento dei dati è facoltativo l’eventuale rifiuto a fornire i dati comporta l’impossibilità di poter usufruire del servizio.
                    <ul className="lista_generica lista_competenze">
                      <li>Lavora con noi per invio candidatura e CV</li>
                    </ul>
                  </p>
                  <p>Starsoftware S.r.l. utilizzerà i suoi dati (dati identificativi e C.V.) per la raccolta dei dati dei candidati per fini di ricerca del personale e archiviazione dei curricula per esigenze future di ricerca del personale.</p>
                  <p>Il trattamento sarà effettuato sulla base del Suo consenso, raccolto mediante conferimento del relativo consenso. Detto che il conferimento dei dati è facoltativo l’eventuale rifiuto a fornire i dati comporta l’impossibilità di poter valutare il profilo e compromettere la candidatura.</p>
                  <p>Per l’informativa sul trattamento di dati raccolti tramite cookie ti rimandiamo alla nostra cookie policy disponibile al <a href="#" target="_blank">seguente link</a>.</p>

                  <h4>MODALITÀ DEL TRATTAMENTO DATI</h4>
                  <p>Starsoftware S.r.l. ha come obiettivo la tutela dei dati che le vengono affidati, improntando il loro trattamento ai principi di correttezza, di liceità e trasparenza. La informiamo, pertanto, che i Suoi dati personali saranno trattati, tramite l’utilizzo di strumenti e procedure idonei a garantirne la massima sicurezza e riservatezza, mediante archivi e supporti cartacei, con l’ausilio di supporti digitali, mezzi informatici e telematici.<br />
                  I dati verranno trattati solo da personale Autorizzato e non saranno soggetti a trasferimento verso paesi terzi.
                  </p>

                  <h4>DURATA DELLA CONSERVAZIONE DEI DATI</h4>
                  <p>I Suoi dati personali saranno conservati, a partire dal loro ricevimento/aggiornamento, per un periodo congruo rispetto alle finalità di trattamento come indicato di seguito:</p>
                  <table style={{width: '100%'}} cellpadding="20">
                    <tbody>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '30px', width: '40%'}}><strong>Finalità/Categorie</strong></td>
                        <td style={{height: '30px', width: '60%'}}><strong>Durata</strong></td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Dati per Iscrizione alla newsletter</td>
                        <td style={{height: '23px', width: '60%'}}>Fino a revoca alla richiesta di revoca del servizio e cancellazione dati da parte dell’interessato.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Sezione lavora con noi</td>
                        <td style={{height: '23px', width: '60%'}}>I dati raccolti (dati personali e CV) saranno conservati fino ad un massimo di 24 mesi.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Sezione contatti</td>
                        <td style={{height: '23px', width: '60%'}}>I dati saranno conservati per massimo 12 mesi a partire dall’ultima comunicazione ricevuta.</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>In ogni caso, fatte salve le disposizioni di legge l’Interessato ha il diritto in qualsiasi momento di richiedere la cancellazione dei propri dati o far valere i propri diritti come riportato nella sezione Diritti dell’Interessato.</p>

                  <h4>CONDIVIDERETE LE MIE INFORMAZIONI CON ALTRI SOGGETTI?</h4>
                  <p>I soggetti che possono venire a conoscenza dei suoi dati personali, nei limiti strettamente necessari per adempiere alle finalità sopra esposte, sono soggetti autorizzati al trattamento dei dati personali da Starsoftware S.r.l. e da Società ad essa collegate.</p>
                  <p>Per lo svolgimento di alcune funzioni inerenti alla gestione della Sua partecipazione all’iniziativa per la quale si è registrato, Starsoftware S.r.l. si rivolge a soggetti e/o Società esterne ai quali fa pervenire i dati necessari e in particolare:
                    <ul className="lista_generica lista_competenze">
                      <li>Iscrizione alla newsletter</li>
                    </ul>
                  </p>
                  <p>Your Mailing List Provider per il trattamento dati con finalità di invio newsletter. Si rimanda al sito del provider per la privacy policy <a href="https://www.ymlp.com/terms-of-service.html" target="_blank">terms of service</a>.</p>

                  <h4>DIRITTI DELL’INTERESSATO</h4>
                  <p>Agli interessati sono riconosciuti, nei casi e nei limiti previsti dal Regolamento, i diritti di cui agli art. da 15 a 20 del Regolamento GDPR. A titolo esemplificativo, ciascun interessato potrà:
                    <ul className="lista_generica lista_competenze">
                      <li>richiedere la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano</li>
                      <li>qualora un trattamento sia in corso, ottenere l’accesso ai dati personali e alle informazioni relative al trattamento nonché richiedere una copia dei dati personali</li>
                      <li>ottenere la rettifica dei dati personali inesatti e l’integrazione dei dati personali incompleti</li>
                      <li>ottenere, qualora sussista una delle condizioni previste dall’art. 17 del GDPR, la cancellazione dei dati personali che lo riguardano</li>
                      <li>ottenere, nei casi previsti dall’art. 18 del GDPR, la limitazione del trattamento</li>
                      <li>nei casi previsti dall’art. 20 del GDPR, ricevere i dati personali che lo riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico e richiedere la loro trasmissione ad un altro titolare, se tecnicamente fattibile</li>
                    </ul>
                  </p>

                  <h4>DIRITTO DI OPPOSIZIONE</h4>
                  <p>Ciascun interessato ha il diritto di opporsi in qualsiasi momento al trattamento dei suoi dati personali effettuato per il perseguimento di un legittimo interesse del Titolare. In caso di opposizione, i suoi dati personali non saranno più oggetto di trattamento, sempre che non sussistano motivi legittimi per procedere al trattamento che prevalgono sugli interessi, sui diritti e sulle libertà dell’interessato oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.</p>

                  <h4>CONTATTI</h4>
                  <p>Per ulteriori informazioni sulla presente informativa o su qualsiasi tematica privacy, o se desidera esercitare i diritti previsti dal Regolamento, potrà rivolgersi direttamente al Titolare del Trattamento tramite i seguenti canali: 
                    <a href="mailto:privacy@starsoftware.it">privacy@starsoftware.it</a>
                  </p>

                </div>
                <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default InformativaSito
